import { MdPhoneIphone, MdInsights } from 'react-icons/md';
import { IoMdEye } from 'react-icons/io';
import { BiBrain, BiChip } from 'react-icons/bi';
import { FaLaptopCode } from 'react-icons/fa';
import { BsGlobe } from 'react-icons/bs';
import UnorderedList from '../components/atoms/UnorderedList';

const skillsData = [
    {
        title: "Machine Learning",
        icon: BiBrain,
        content: <UnorderedList items={[
            "Generative AI (LLMs).",
            "Traditional ML and deep learning models.",
            "ML Frameworks: Pytorch, Tensorflow, Keras, scikit-learn.",
        ]} />
    },
    {
        title: "Data Science",
        icon: MdInsights,
        content: <UnorderedList items={[
            "Advanced data analytics, and statistical modeling.",
            "Google Cloud Services (GCP).",
            "Business intelligence and visualization with Python/PowerBI.",
            "Data integration and ETL workflow design.",
        ]} />,
    },
    {
        title: "Computer Vision",
        icon: IoMdEye,
        content: <UnorderedList items={[
            "Computer vision model development",
            "Real-time image processing, and object detection.",
            "Multi-modal LLM for vision tasks.",
            "OpenCV",
        ]} />,
    },
    {
        title: "Software Engineering",
        icon: FaLaptopCode,
        content: <UnorderedList items={[
            "System architecture and database design.",
            "Languages: Python, Ruby, JavaScript. Familiar with many others.",
            "Advanced algorithm design and optimization.",
            "Agile development and technical project management.",
        ]} />,
    },
    {
        title: "Embedded Systems / IoT",
        icon: BiChip,
        content: <UnorderedList items={[
            "Edge computing and embedded AI systems.",
            "IoT device development and sensor integration.",
            "Real-time data processing systems.",
            "Experience with Raspberry Pi, BeagleBone, and custsom PCBs.",
        ]} />,
    },
    {
        title: "Web Development",
        icon: BsGlobe,
        content: <UnorderedList items={[
            "Full-stack development with React.js and Node.js / Python",
            "RESTful and GraphQL API design.",
            "Backend services and database integration.",
        ]} />,
    },
    {
        title: "Mobile Development",
        icon: MdPhoneIphone,
        content: <UnorderedList items={[
            "Android development Kotlin and Java.",
        ]} />,
    },
];

export default skillsData;