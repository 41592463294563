import React from 'react';
import IntroSection from '../components/templates/IntroSection';
import Image from '../components/atoms/Image';
import './HomePage.scss';
import Experience from '../components/templates/Experience';
import Education from '../components/templates/Education';
import Skills from '../components/templates/Skills';
import Projects from '../components/templates/Projects';
import Presentations from '../components/templates/Presentations';
import Courses from '../components/templates/Courses';
import { useState } from 'react';
import LoadingScreen from './LoadingScreen';
import NewsTicker from '../components/molecules/NewsTicker';

const HomePage = () => {
    const [showContent, setShowContent] = useState(false);
    console.log(showContent);
    const handleVideoEnd = () => {
        setShowContent(true);
    };

    return (
        showContent ? (
            <>
                {/* <NewsTicker /> */}
                
                {/* Introduciton */}
                <IntroSection />

                {/* OU Image */}
                {/* <Image src="assets/images/ou.jpg" alt="Trees and buildings on OU's campus in Norman, Oklahoma." title="Evans Hall on OU's campus in Norman, Oklahoma." className="ou-background-image" /> */}
                <Image src="assets/images/vermont-w1.jpg" alt="Vermont | credit https://wallpapercave.com/w/wp2381283" title="Vermont, Foliage" className="space-background-image" />

                {/* Job Experience */}
                <Experience />

                {/* Education */}
                <Education />

                {/* Skills */}
                <Skills />

                {/* Projects */}
                <Projects />

                {/* Presentations */}
                <Presentations />

                {/* Courses */}
                <Courses />
            </>
        ) : <LoadingScreen onVideoEnd={handleVideoEnd} />
    );
}

export default HomePage;