const introParagraph = (
  <>
    Hi, I'm Ali, <br />
    <br />A <strong>data scientist</strong> with over 4 years of experience in
    data science, AI/ML, and full-stack software engineering.
    <br />
    <br />
    <b>At Hatch:</b> I build end-to-end AI solutions and data-driven software
    systems for enterprise clients. My work involves developing computer vision
    models and LLM applications, writing custom algorithms, software system
    design and development, and building AI-enabled IoT systems.
    <br />
    <br />
    <b>Previously:</b> As a graduate research assistant at OU, my research
    focused on applied ML, and data science for weather and transportation
    applications. I built ML models, an IoT mobile application, and conducted
    dynamics analysis. Earlier roles include software engineering for web and
    mobile and data engineering building automated data collection tools.
  </>
);

export default introParagraph;
